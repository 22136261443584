import HomePage from './Containers/home'

function App() {
  return (
    <div className="App" style={{  height:"100vh"}}>
      
      <HomePage/>
    
      
    </div>
  );
}

export default App;
